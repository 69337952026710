/*
 * HEADINGS
 */

h1{
  font-family: $h1-font-family;
  font-size: $h1-font-size;
  line-height: $h1-line-height;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: $h1-color;
  margin-bottom: 0.5em;
}

h2{
  font-family: $h2-font-family;
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: $h2-color;
  margin-bottom: 0.5em;

  &:after{
    content: '';
    display: block;
    width: 50px;
    height: 0px;
    margin-top: .5rem;
    border-bottom: 3px solid $primary-color;
  }
}

h3{
  font-family: $h3-font-family;
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: $h3-color;
  margin-bottom: 0.5em;
}

h4{
  font-family: $h4-font-family;
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  color: $h4-color;
  margin-bottom: 0.5em;
}

h5{
  font-family: $h5-font-family;
  font-size: $h5-font-size;
  line-height: $h5-line-height;
  color: $h5-color;
  margin-bottom: 0.5em;
}

h6{
  font-family: $h6-font-family;
  font-size: $h6-font-size;
  line-height: $h6-line-height;
  color: $h6-color;
  margin-bottom: 0.5em;
}