/*
 * MIXINS
 */

// MEDIA QUERIES
@mixin media-query($breakpoint, $query1: min, $query2: width) {
  $query: map-get($breakpoints, $breakpoint);

  @if $query != null {
    @media (#{$query1}-#{$query2}: #{$query}) {
        @content;
    }
  } @else {
    @media (#{$query1}-#{$query2}: $breakpoint) {
        @content;
    }
  }
}

// LAST #
@mixin last($i: 1){
  &:nth-last-child(-n+#{$i}){
    @content;
  }
}

// FIRST #
@mixin first($i: 1){
  &:nth-child(-n+#{$i}){
    @content;
  }
}

// EVERY #
@mixin every($i: 1){
  &:nth-child(#{$i}n+1){
    @content;
  }
}

// ODD
@mixin odd(){
  &:nth-child(odd){
    @content;
  }
}

// EVEN
@mixin even(){
  &:nth-child(even){
    @content;
  }
}