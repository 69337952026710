.c-intro{
  font-size: 1.4rem;
  font-weight: 500;
}

.c-usp-items{
  h3{
    color: $secondary-color;
  }
}

.c-usp-items-home{
  li{
    list-style-type: circle;
    color: $lemon;
  }
}

.c-usp-paragraph{
  font-size: 2rem;
  font-weight: bold;

  span{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $lemon;
  }
}