// Colors.
$primary-color: #4db140;
$secondary-color: #00adef;
$white: #fff;
$black: #000;
$darkgrey: #3E454C;
$grey: #646A6F;
$lightgrey: #FAFBFE;
$pine: #1a4f4f;
$lemon: #cdffaf;

$font-color: $grey;

// Sizes.
$font-size: 16px;
$line-height: 1.5;
$heading-margin: 0.7em;

// Block
$block-padding: 100px;

// Buttons.
$btn-padding: 9px 26px;
$btn-bg-color: $primary-color;
$btn-text-color: #FFF;
$btn-hover-text-color: #FFF;
$btn-hover-bg-color: darken($btn-bg-color, 10%);

$btn-white-bg-color: $white;
$btn-white-text-color: $black;
$btn-white-hover-text-color: $btn-white-text-color;
$btn-white-hover-bg-color: #DEDEDE;

$btn-black-bg-color: $black;
$btn-black-text-color: $white;
$btn-black-hover-text-color: #FFF;
$btn-black-hover-bg-color: lighten($btn-black-bg-color, 5%);

$btn-border-radius: 10px;

$btn-shadow: 1;

$headings: (
  h1: 2.75rem,
  h2: 2rem,
  h3: 1.125rem,
  h4: 1rem,
  h5: 0.9rem,
  h6: 0.75rem
);

$headings-margin-bottom: (
  h1: 1em,
  h2: 1em,
  h3: 1em,
  h4: 10px,
  h5: 10px,
  h6: 10px
)
