/*
 * SETTINGS
 */

// BREAKPOINTS
$breakpoints: (
  bp1: 480/16*1rem,
  bp2: 640/16*1rem,
  bp3: 960/16*1rem,
  bp4: 1024/16*1rem,
  bp5: 1140/16*1rem
);

// GRID
$columns: 12;
$gutter: 20/16*1rem;

// FONTS
$base-font-family: 'Work Sans', Helvetica, sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5;
$base-color: $grey;

$h1-font-family: 'Work Sans', Helvetica, sans-serif;
$h1-font-size: 3rem;
$h1-line-height: 1;
$h1-color: $darkgrey;

$h2-font-family: 'Work Sans', Helvetica, sans-serif;
$h2-font-size: 2.25rem;
$h2-line-height: 1.25;
$h2-color: $darkgrey;

$h3-font-family: 'Work Sans', Helvetica, sans-serif;
$h3-font-size: 1.25rem;
$h3-line-height: 1.25;
$h3-color: $darkgrey;

$h4-font-family: 'Work Sans', Helvetica, sans-serif;
$h4-font-size: 1rem;
$h4-line-height: 1.25;
$h4-color: $darkgrey;

$h5-font-family: 'Work Sans', Helvetica, sans-serif;
$h5-font-size: .75rem;
$h5-line-height: 1.25;
$h5-color: $darkgrey;

$h6-font-family: 'Work Sans', Helvetica, sans-serif;
$h6-font-size: .5rem;
$h6-line-height: 1.25;
$h6-color: $darkgrey;

// COLORS
$info: #2196f3;
$success: #4caf50;
$warning: #ffc107;
$error: #f44336;