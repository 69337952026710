/*
 * CARDS
 */

.c-card-grid{
  > .o-col{
    margin-bottom: $gutter;

    @include last(){
      margin-bottom: 0;
    }

    @include media-query(bp3){
      @include last(3){
        margin-bottom: 0;
      }
    }
  }
}

.c-card{
  display: block;
  background: white;
  box-shadow: 0 3px 18px rgba(black, 0.05);
}

a.c-card{
  transition: all 0.2s ease;

  &:hover{
    box-shadow: 0 6px 15px rgba(black, 0.15);
  }
}