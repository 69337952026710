/*
 * MAIN
 */
 
.c-main{
  background-color: $lightgrey;

  .container-nav{
    background-color: darken(white, 5);
    margin-bottom: 50px;
    padding: 0;
  }
}

.c-main-home{
  background-color: $pine;
  color: white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    color: inherit;
  }
}

@media (min-width: 768px) {
  .c-main{
    padding-top: 0 !important;

    .container:not(.container-nav){
      padding: 75px 50px;
      background-color: white;
      box-shadow: 0 3px 18px rgba(black, 0.05);
    }

    .container-nav{
      margin-bottom: 0;
    }
  }
}

.c-main-has-subnav{
  padding-top: 0 !important;
}