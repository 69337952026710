/*
 * HEADER
 */

 .c-header{

 }

.c-header-logo{
  flex: 0 0 auto;

  img{
    with: 200px;
    height: 87px;
  }
}

.c-header-primary{

  .navbar{
    padding: 16px 0;
  }

  .nav-link{
    font-size: 1.15rem;
    font-weight: 500;
  }
}

.c-header-secondary{
  background-color: $darkgrey;

  .navbar{
    padding: 8px 0;
  }
}