/*
 * HORIZONTAL RULE
 */

hr{
  width: 100%;
  height: 1px;
  margin: $gutter*4 0;
  border: 0;
  border-top: 1px solid #ccc;
}