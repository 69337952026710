/*
 * CONTAINER
 */

.o-container{
  width: 100%;
  margin: 0 auto;
  padding: 0 $gutter;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      max-width: map-get($breakpoints, $bp);
    }
  }
}