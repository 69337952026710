/*
 * FOOTER
 */

.c-footer{
  .navbar{
    padding: 0;
  }

  .nav-link{
    padding: 0;
  }
}