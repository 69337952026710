/*
 * NAVIGATION
 */

.c-header-secondary{
  .nav-link{
    color: white;

    &:not([href^="tel:"]) span{
      border-bottom: 1px solid white;
      transition: border-bottom-color 0.1s ease;
    }

    &:hover:not([href^="tel:"]) span{
      border-bottom-color: $secondary-color;
    }
  }
}

.c-header-primary{
  .nav-link{
    font-size: 1rem;
    transition: color 0.1s ease;
    
    &.is-active{
      font-weight: 600;
      color: $primary-color;
    }

    &:hover{
      color: $primary-color;
    }
  }
}

.c-subnav{
  padding: 0;

  .nav-item{
    border-left: 1px solid white;

    &:first-child{
      border-left: none;
    }
  }

  .nav-link{
    display: block;
    padding: 1.2rem 2rem !important;
    font-size: 1rem;
    font-weight: 500;
    color: $darkgrey;
    transition: all 0.1s ease;

    &:hover,
    &.is-active{
      background-color: $secondary-color;
      color: white;
    }
  }
}


@media (min-width: 992px) {
  .c-header-primary{
    .nav-link{
      font-size: 1.15rem;
    }
  }
}