.u-clear{
  &-left{
    clear: left;

    @each $bp in map-keys($breakpoints){
      @include media-query($bp){
        &-#{$bp}{
          clear: left;
        }
      }
    }
  }

  &-right{
    clear: right;

    @each $bp in map-keys($breakpoints){
      @include media-query($bp){
        &-#{$bp}{
          clear: right;
        }
      }
    }
  }

  &-both{
    clear: both;

    @each $bp in map-keys($breakpoints){
      @include media-query($bp){
        &-#{$bp}{
          clear: both;
        }
      }
    }
  }

  &-none{
    clear: none;

    @each $bp in map-keys($breakpoints){
      @include media-query($bp){
        &-#{$bp}{
          clear: none;
        }
      }
    }
  }
}