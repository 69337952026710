/*
 * BUTTONS
 */

.c-button{
  display: inline-block;
  padding: 0.75em 1em;
  background-color: $info;
  border: 1px solid darken($info, 10%);
  line-height: normal;
  color: white;
  transition: all 0.1s ease;

  &:hover{
    background-color: darken($info, 10%);
  }
}

.c-button-success{
  background-color: $success;
  border-color: darken($success, 10%);

  &:hover{
    background-color: darken($success, 10%);
  }
}

.c-button-warning{
  background-color: $warning;
  border-color: darken($warning, 10%);

  &:hover{
    background-color: darken($warning, 10%);
  }
}

.c-button-error{
  background-color: $error;
  border-color: darken($error, 20%);

  &:hover{
    background-color: darken($error, 20%);
  }
}