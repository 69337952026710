/*
 * BASE
 */

html{
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p strong,
p em,
ul strong,
ul em,
ol strong,
ol em{
  color: $darkgrey;
}

.wysi{
  a:not(.btn){
    font-weight: 500;
    color: $darkgrey;
    border-bottom: 1px solid $base-color;
    transition: all 0.1s ease;

    &:hover{
      border-bottom-color: transparent;
    }
  }

  h1{
    font-size: 2.5rem;
  }

  h2{
    font-size: 1.75rem;

    &:after{
      content: none;
    }
  }
}