/*
 * LISTS
 */

ul,
ol{
  margin-bottom: 1em;
}

li {
  ul,
  ol {
    margin-top: 0.5em;
    margin-left: 1em;
  }

  ol{
    list-style-type: lower-latin;
  }
}