/*
 * TEXT
 */

.u-text-container{
  > *:last-child{
    margin-bottom: 0;
  }
}

.u-text-left{
  text-align: left;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        text-align: left;
      }
    }
  }
}

.u-text-right{
  text-align: right;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        text-align: right;
      }
    }
  }
}

.u-text-center{
  text-align: center;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        text-align: center;
      }
    }
  }
}