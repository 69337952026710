/*
 * FORMS
 */

.c-form{
  > *:last-child{
    margin-bottom: 0;
  }
}

.c-form-group{
  margin-bottom: 1.5em;
}

.c-label{
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.c-form-input-text,
.c-form-input-password{
  width: 100%;
  max-width: 320px;
  padding: 0.75em 1em;
  border: 1px solid transparent;
  background-color: darken(white, 5%);
  transition: all 0.2s ease;

  &:focus{
    outline: none;
    border: 1px solid darken(white, 15%);
  }
}

.c-form-input-radio-container{
  label{
    position: relative;
    padding-left: 24px;
    cursor: pointer;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      margin: 1px 0;
      display: inline-block;
      vertical-align: middle;
      background-color: darken(white, 10%);
      border-radius: 50%;
    }

    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      margin: 4px 3px;
      display: inline-block;
      vertical-align: middle;
      background-color: darken(white, 75%);
      border-radius: 50%;
      opacity: 0;
      transform: scale(0);
      transition: all 0.2s ease;
    }
  }

  input{
    display: none;
  }

  input:checked + label{
    &:after{
      opacity: 1;
      transform: scale(1);
    }
  }
}

.c-form-input-checkbox-container{
  label{
    position: relative;
    padding-left: 24px;
    cursor: pointer;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      margin: 1px 0;
      display: inline-block;
      vertical-align: middle;
      background-color: darken(white, 10%);
    }

    &:after{
      content: '\2714';
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      margin: 4px 3px;
      display: inline-block;
      vertical-align: middle;
      font-size: rem(10);
      text-align: center;
      line-height: 10px;
      color: darken(white, 75%);
      opacity: 0;
      transform: scale(0);
      transition: all 0.2s ease;
    }
  }

  input{
    display: none;
  }

  input:checked + label{
    &:after{
      opacity: 1;
      transform: scale(1);
    }
  }
}

.c-form-input-select-container{
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 320px;
  background-color: darken(white, 5%);

  &:after{
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1em;
    margin-top: -3px;
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid darken(white, 50%);
  }

  select{
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0.75em 1em;
    padding-right: calc(2em + 12px);
    appearance: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    line-height: normal;
    cursor: pointer;
    transition: all 0.2s ease;

    &:focus{
      outline: none;
      border: 1px solid darken(white, 15%);
    }
  }
}

/* 
 * Processwire
 */

label{
  font-weight: 500;
  color: $darkgrey;

  .InputfieldStateRequired &{
    &:after{
      content: ' *';
    }
  }
}