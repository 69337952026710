/*
 * FORMS
 */

input,
button{
  border: 0;
}

select{
  appearance: none;
}