/*
 * DISPLAY
 */

// BLOCK
.o-block{
  display: block;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        display: block;
      }
    }
  }
}

// INLINE BLOCK
.o-inline-block{
  display: inline-block;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        display: inline-block;
      }
    }
  }
}

// INLINE
.o-inline{
  display: inline;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        display: inline;
      }
    }
  }
}

// NONE
.o-none{
  display: none;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        display: none;
      }
    }
  }
}