/*
 * ALERTS
 */

.c-alert{
  padding: 1em;
  background-color: $info;
  border-top: 2px solid darken($info, 10%);
  color: white;
}

.c-alert-success{
  background-color: $success;
  border-top-color: darken($success, 10%);
}

.c-alert-warning{
  background-color: $warning;
  border-top-color: darken($warning, 10%);
}

.c-alert-error{
  background-color: $error;
  border-top-color: darken($error, 20%);
}

.c-alert-close{
  margin: 0 0 1em 1em;
  float: right;
  width: 18px;
  height: 18px;
  font-size: rem(18);
  text-align: center;
  line-height: 18px;
}